import { getAllProfesionalesService } from '../services/data.service';

export const transformDate = (date) => {
    if (date === '') return '';
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(date)) return date;
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const d = new Date(date).toLocaleDateString('es-AR', options);
    return d.split('/').reverse().join('-');
};

export const transformDateToDay = (date) => {
    if (date === '') return '';
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(date)) return date;
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const d = new Date(date).toLocaleDateString('es-AR', options);
    return d.split('/').reverse().join('-');
};

export const transformData = (data) => {
    if (data === undefined) return [];
    const x = (data.map((d) => ({ id: d.id, text: `${d.Nombre} - ${d.NumDocumento}` })));
    return x;
};

export async function turnsWithPatients (turns) {
    const formattedTurns = [];
    for (const turn of turns) {
        formattedTurns.push({
            id: turn.id,
            hora: turn.hora,
            profesional: turn.profesional ? turn.profesional.Apellido + ' ' + turn.profesional.Nombre : null,
            modify_paciente: turn.paciente ? turn.paciente : null,
            paciente: turn.paciente ? turn.paciente.Nombre : '-',
            paciente_id: turn.paciente ? turn.paciente.id : '-',
            presente: turn.presente,
            primeraVisita: turn.primeraVisita,
            atendido: turn.atendido,
            obraSocial: turn.paciente && turn.obra_social ? turn.obra_social.codigo + ' - ' + turn.obra_social.descripcion : '-',
            telefono: turn.paciente ? turn.paciente.Telefono : '-',
            observaciones: turn.observ
        });
    }
    return formattedTurns;
}

export function transformSearchPatient (paciente) {
    return ({
        id: paciente.id,
        antecedentes: paciente.Antecedentes,
        data: {
            paciente: paciente.Nombre,
            documento: paciente.NumDocumento,
            fechaNacimiento: paciente.FechaNacimiento && paciente.FechaNacimiento.split('-').reverse().join('/'),
            sexo: paciente.Genero,
            domicilio: paciente.Direccion,
            localidad: paciente.ciudad,
            obraSocial: paciente.obra_social ? paciente.obra_social.codigo + ' - ' + paciente.obra_social.descripcion : '-',
            telefono: paciente.Telefono
        }
    });
}

export function calculateAge (bornDate) {
    if (!bornDate) return;
    const today = new Date();
    const birthDate = new Date(bornDate.split('/').reverse('-'));
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export async function transformedFeriados (feriados) {
    const professionals = await getAllProfesionalesService();

    return feriados.map((feriado) => ({
        id: feriado.id,
        prof: feriado.prof_cod, // TODO: REEMPLAZAR CON EL NOMBRE DEL PROFESIONAL
        fecha: feriado.fecha.split('-').reverse().join('/'),
        titulo: feriado.titulo
    }))
        .filter((feriado, index) => {
            const actualYear = new Date().getFullYear().toString() === feriado.fecha.split('/')[2];
            return feriado.fecha !== (feriados[index - 1]?.fecha.split('-').reverse().join('/')) && actualYear;
        })
        .sort((a, b) => new Date(a.fecha.split('/').reverse().join('-')) - new Date(b.fecha.split('/').reverse().join('-')))
        .map((feriado) => {
            if (feriado.titulo.toUpperCase() !== 'FERIADO') {
                const prof = professionals.find((p) => p.codigo === feriado.prof);
                feriado.prof = prof && prof.text;
            }
            return feriado;
        });
}

export function checkNameDNI (value) {
    const number = Number(value);
    if (!isNaN(number)) {
        return number;
    }
    return value;
}
