/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { tableColumns } from '../logic/obraSocial.js';
import { getAllObrasocialesNotFormat, putObraSocial } from '../services/data.service.js';
import { errorPopup } from '../components/popup.jsx';
import EditTable from '../parts/EditTable.jsx';
import Pagination from '../components/Pagination.jsx';

export default function ObraSocial ({ darkMode }) {
    const [loading, setLoading] = useState(false);
    const [allObrasSociales, setAllObrasSociales] = useState([]);
    const [dateTable, setDateTable] = useState([]);

    useEffect(() => {
        const fetchObrasSociales = async () => {
            try {
                setLoading(true);
                const obrasSocialesData = await getAllObrasocialesNotFormat();
                if (obrasSocialesData.status) {
                    setAllObrasSociales(obrasSocialesData.obras_sociales);
                    setDateTable(obrasSocialesData.obras_sociales.slice(0, 10));
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error al obtener las obras sociales:', error);
            }
        };

        fetchObrasSociales();
    }, []);

    const handleChange = async (id, field, value) => {
        try {
            const response = await putObraSocial(id, { [field]: value });
            if (response === true) {
                console.log(response);
            } else {
                errorPopup({ title: 'Error', text: response });
                return [];
            }
        } catch (error) {
            console.error('Error al actualizar la obra social:', error);
            errorPopup({ title: 'Error', text: 'Hubo un error al actualizar la obra social.' });
        }
    };

    const handlePagination = (page) => {
        const startIndex = (page - 1) * 10;
        const endIndex = startIndex + 10;
        setDateTable(allObrasSociales.slice(startIndex, endIndex));
    };

    return (
        <section className="p-16">
            <h2 className="text-3xl flex justify-center my-10 text-principal font-bold">
                <p>Obras sociales</p>
            </h2>
            <section className='m-8'>
                <EditTable
                    darkMode={darkMode}
                    cells={tableColumns}
                    dataRows={dateTable}
                    loadingEnable={loading}
                    onChange={handleChange}
                />
                <Pagination initialValue={1} totalNumbers={allObrasSociales.length / 10} onChange={handlePagination} />
            </section>
        </section>
    );
}
