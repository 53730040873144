import React from 'react';
import { InputText } from '../components/inputText';

export default function AddLocalidad () {
    return (
        <form id="locationForm" className="mx-auto bg-white">
            <div className="m-4">
                <InputText className="px-16" darkMode="" ide="add_codigo_postal" label="Código postal" />
            </div>
            <div className="m-4">
                <InputText className="px-16" darkMode="" ide="add_localidad" label="Localidad" />
            </div>
            <div className="m-4">
                <InputText className="px-16" darkMode="" ide="add_provincia" label="Provincia" />
            </div>
        </form>
    );
}
