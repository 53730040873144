import { errorPopup, warningPopup } from '../components/popup';
import { deleteHorarioService } from '../services/data.service';
export const checkHour = (desde, hasta) => {
    if (new Date('2000-01-01T' + hasta) < new Date('2000-01-01T' + desde)) {
        errorPopup({ title: 'Error', text: '"Hasta" no puede ser menor a "Desde"' });
        return false;
    }
    return true;
};

export const horarioValidations = (data, tableSchedule) => {
    return checkRow(data, tableSchedule) && checkHour(data.desde, data.hasta);
};

export const checkRow = (data, tableSchedule) => {
    const index = tableSchedule.findIndex(
        (d) =>
            d.nombre_profesional.toUpperCase() === data.nombre_profesional.toUpperCase() &&
            d.dia.toUpperCase() === data.dia.toUpperCase() &&
            d.desde === data.desde &&
            d.hasta === data.hasta
    );
    if (index !== -1) {
        errorPopup({ title: 'Error', text: 'El horario ya existe' });
        return false;
    }
    return true;
};

export const allInputsFilled = (schedule) => {
    return schedule.nombre_profesional !== undefined && Object.entries(schedule).every(([_, value]) => value !== '');
};

export const deleteHorario = async (id) => {
    const response = await warningPopup({
        title: 'Advertencia',
        text: '¿Está seguro que desea eliminar este horario?'
    })
        .then(async (response) => {
            if (response.isConfirmed) {
                const response = await deleteHorarioService(id);
                return response;
            }
            return false;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
    return response;
};
