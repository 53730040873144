import React from 'react';
import { formPopup } from '../components/popup';
import CreateFeriado from './CreateFeriado';
import { saveFeriadoFromSchedule } from '../services/data.service';
import Boton from '../components/boton';

export function CreateNewFeriado () {
    const handleAddFeriado = () => {
        formPopup({
            title: 'Nuevo feriado',
            form: <CreateFeriado/>
        })
            .then((response) => {
                if (response.isConfirmed) {
                    const inputs = Array.from(document.getElementById('createFeriado').getElementsByTagName('input'));
                    const notEmpty = inputs.every((i) => i.value.trim() !== '');
                    if (notEmpty) {
                        const newFeriado = {
                            titulo: inputs[0].value,
                            fecha: inputs[1].value.split('/').reverse().join('-'),
                            motivo: inputs[2].value,
                            profesional: inputs[3].value
                        };
                        saveFeriadoFromSchedule(newFeriado);
                    }
                }
            });
    };

    return (
        <Boton color={'bg-[#314865] hover:bg-[#414865]'} ide="saveFeriado" texto={'Crear feriado'} estilo={'mt-6 h-fit w-fit justify-self-end'} onClick={ handleAddFeriado }/>

    );
}
