import { putTurn, getTurnsByDateProfessionalLibre, getTurnsByDateProfessional } from '../services/data.service';
import { transformDate, turnsWithPatients } from './utils';

export async function handleCheckboxChange (event, name, row) {
    const response = await putTurn(row.id, { [name]: event.target.checked });
    try {
        console.log(response);
    } catch (err) {
        console.error(err);
        return [];
    }
}

export const tableColumns = [
    { field: 'hora', headerName: 'Hora', editable: false },
    { field: 'paciente', headerName: 'Paciente', editable: false },
    { field: 'profesional', headerName: 'Profesional', editable: false },
    {
        field: 'presente',
        headerName: 'Pres.',
        options: {
            type: 'checkbox',
            onChange: handleCheckboxChange
        }
    },
    {
        field: 'primeraVisita',
        headerName: '1ra.Vez',
        options: {
            type: 'checkbox',
            onChange: handleCheckboxChange
        }
    },
    {
        field: 'atendido',
        headerName: 'Atendido',
        options: {
            type: 'checkbox',
            onChange: handleCheckboxChange
        }
    },
    { field: 'obraSocial', editable: false, headerName: 'Obra social' },
    { field: 'telefono', editable: false, headerName: 'Teléfono' },
    { field: 'observaciones', editable: true, headerName: 'Observaciones' }
];

export const getSchedules = async (fecha, prof) => {
    if (fecha === null || (prof && prof[0].codigo === -1)) {
        return;
    }
    try {
        const scheduleService = await getTurnsByDateProfessionalLibre(transformDate(fecha), prof[0].codigo);
        if (scheduleService !== undefined) {
            return scheduleService;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getTurnsProfDate = async (fecha, prof) => {
    if (fecha === null || (prof && prof[0].codigo === -1)) {
        return;
    }
    try {
        const scheduleService = await getTurnsByDateProfessional(transformDate(fecha), prof[0].codigo);
        if (scheduleService !== undefined) {
            const formattedTurns = turnsWithPatients(scheduleService);
            return formattedTurns;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getTurns = async (fecha, prof) => {
    if (fecha === null) {
        return;
    }
    try {
        const turnService = await getTurnsByDateProfessional(transformDate(fecha), prof[0].codigo);
        if (turnService !== undefined) {
            return turnsWithPatients(turnService);
        }
        return [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const formattedPaciente = (paciente) => {
    return {
        id: paciente.id,
        data: {
            documento: paciente.NumDocumento,
            fechaNacimiento: paciente.FechaNacimiento && paciente.FechaNacimiento.split('-').reverse().join('/'),
            domicilio: paciente.Direccion,
            localidad: paciente.ciudad,
            obraSocial: paciente.obra_social ? paciente.obra_social.codigo + ' - ' + paciente.obra_social.descripcion : '',
            paciente: paciente.Nombre,
            sexo: paciente.Genero,
            telefono: paciente.Telefono,
            antecedentes: paciente.Antecedentes
        }
    };
};

export const filterTurns = (allTurns) => {
    if (allTurns.length === 0) {
        return [];
    }

    return allTurns.map((turno) => ({
        id: turno.id,
        hora: turno.hora,
        paciente: turno.paciente ? turno.paciente.Nombre : '-',
        documento: turno.paciente ? turno.paciente.NumDocumento : '-',
        obraSocial: turno.obra_social ? turno.obra_social.descripcion : '-',
        paciente_id: turno.paciente_id,
        primeraVisita: turno.primeraVisita,
        atendido: turno.atendido,
        presente: turno.presente,
        observaciones: turno.observ
    }));
};

export const columnsPresentes = [
    { field: 'hora', headerName: 'Hora', editable: false },
    { field: 'paciente', headerName: 'Paciente', editable: false },
    { field: 'documento', headerName: 'Documento', editable: false },
    { field: 'obraSocial', headerName: 'Obra social', editable: false },
    {
        field: 'presente',
        headerName: 'Pres.',
        options: {
            type: 'checkbox',
            disabled: true,
            onChange: handleCheckboxChange
        }
    },
    {
        field: 'primeraVisita',
        headerName: '1ra.Vez',
        options: {
            type: 'checkbox',
            onChange: handleCheckboxChange
        }
    },
    {
        field: 'atendido',
        headerName: 'Atendido',
        options: {
            type: 'checkbox',
            onChange: handleCheckboxChange
        }
    },
    { field: 'observaciones', editable: false, headerName: 'Observaciones' }
];
