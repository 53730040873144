import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export const Loading = ({ open, login }) => {
    const backdropStyle = {
        zIndex: 9999,
        color: '#fff',
        background: 'rgba(0, 0, 0, 0.5)', // Fondo semitransparente,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px'
    };
    return (
        <Backdrop open={open} style={backdropStyle}>
            <CircularProgress color="inherit" size={100}/>
            {login && <h4 className='font-bold text-2xl'>Iniciando sesión...</h4>}
        </Backdrop>
    );
};
