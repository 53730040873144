import React, { useEffect, useState } from 'react';
import EditTable from '../parts/EditTable';
import InputSelect from '../components/inputSelect.jsx';
import { formattedPaciente, filterTurns, columnsPresentes } from '../logic/turnos';
import { getPacienteById, getTurnsByDateProfessional, getProfesionalByCodigo } from '../services/data.service';
import { useNavigate } from 'react-router-dom';
import { useProfessionalsActive } from '../hooks/useProfessionalsActive.jsx';
import { transformDate } from '../logic/utils';
import { LocalizationProvider, esES, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';

export const PacientesPresentes = (pars) => {
    const { professionals } = useProfessionalsActive();
    const [professional, setProfessional] = useState([{ codigo: -1, text: '' }]);
    const [turns, setTurns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const fetchProfessional = async () => {
            const codProf = JSON.parse(sessionStorage.getItem('user')).profCodToken;
            if (!codProf && codProf !== 0) return; // Si no hay código de profesional, no se hace nada
            const foundProfessional = await getProfesionalByCodigo(codProf);
            if (foundProfessional) {
                setProfessional([{ codigo: codProf, text: foundProfessional.Apellido + ' ' + foundProfessional.Nombre }]);
            }
        };
        fetchProfessional();
    }, []);

    useEffect(() => { // Se ejecuta apenas entro a la pagina
        if (professional[0] && professional[0].codigo !== -1 && date !== null) {
            getTurns(professional[0].codigo, date);
        }
    }, [professional, date]);

    useEffect(() => {
        if (professional[0] && professional[0].codigo !== -1) {
            const intervalId = setInterval(async () => {
                // Obtener todos los turnos y luego filtrar solo los necesarios
                const allTurns = await getTurnsByDateProfessional(transformDate(date), professional[0].codigo);
                const newTurns = filterTurns(allTurns);
                setTurns(newTurns);
            }, 10000); // Intervalo de un minuto

            // Limpieza del intervalo al desmontar el componente
            return () => clearInterval(intervalId);
        }
        // Si professional[0] no existe o es igual a -1, no se ejecuta el intervalo
    }, [professional, date]);

    const handleChangeSelector = async (event, opt) => {
        setProfessional(opt);
        await getTurns(opt[0].codigo, date);
    };

    const getTurns = async (profCodigo, fecha) => {
        if (fecha !== null && profCodigo !== -1) {
            setLoading(true);
            const response = await getTurnsByDateProfessional(transformDate(fecha), profCodigo);
            if (!response || response.length === 0) {
                // Si no hay datos en el response, no actualices los turnos y detén la carga
                setLoading(false);
                setTurns([]);
                return;
            }
            // TODO: consultar si se deben mostrar solo los presentes o todos.
            const newTurns = filterTurns(response);
            setTurns(newTurns);
            setLoading(false);
        }
    };

    const navigate = useNavigate();
    const onNavigate = async (id) => {
        const turnoWithId = turns.find((turno) => turno.id === id);
        let paciente = await getPacienteById(turnoWithId.paciente_id);
        paciente = formattedPaciente(paciente);
        navigate('/historico', { state: { paciente, turnId: id } });
    };

    const handleDateChange = (opt) => {
        setDate(opt);
        getTurns(professional[0].codigo, opt);
    };

    return (
        <section className='p-16'>
            <h1 className='text-3xl flex justify-center my-5 text-principal font-bold'>Pacientes Presentes</h1>
            <div className='mt-16 flex'>
                <aside className='w-1/3 mx-auto'>
                    <LocalizationProvider
                        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                        dateAdapter={AdapterDateFns}
                        adapterLocale={es}
                    >
                        <DateCalendar
                            sx={{
                                width: '100%',
                                '& .MuiDayCalendar-header': {
                                    display: 'flex !important',
                                    justifyContent: 'space-around !important',
                                    width: '100%'
                                },
                                '& .MuiDayCalendar-weekContainer': {
                                    display: 'flex !important',
                                    justifyContent: 'space-around !important',
                                    width: '100%'
                                },
                                '& .Mui-selected': {
                                    backgroundColor: '#359597 !important'
                                }
                            }}
                            onChange={handleDateChange}
                            value={date}
                        />
                    </LocalizationProvider>
                </aside>
                <section className='flex flex-col w-2/3 gap-5'>
                    <InputSelect initialValue={professional[0].text} pars={{ ide: 'professional', darkMode: pars.darkMode, label: 'Seleccione un profesional' }} data={professionals} onChange={handleChangeSelector}/>
                </section>
            </div>
            <div className='flex flex-col'>
                {
                    turns.length > 0 &&
                    <div className='flex legend gap-2 m-3'>
                        <label className='flex items-center gap-1' htmlFor='color1' >
                            Presente
                            <input id='color1' type="color" value="#beac35" disabled className='w-6 h-6 rounded' />
                        </label>
                        <label className='flex items-center gap-1' htmlFor='color2'>
                            Atendido
                            <input id='color2' type="color" value="#00aab0" disabled className='w-6 h-6 rounded' />
                        </label>
                    </div>
                }
                <EditTable darkMode={pars.darkMode} actionsColumn={ true } navigateHistorial={ onNavigate } cells={columnsPresentes} dataRows={turns} loadingEnable={loading}/>
            </div>
        </section>
    );
};
