import React from 'react';
import { DEFAULT_BOTON_CLASSNAME, DEFAULT_THEME_BUTTON, DARK_THEME_BUTTON } from '../constants/const_tailwind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Boton = ({ ide, texto, onClick, estilo, icono, darkMode, disabled, color }) => {
    let clasesBoton = estilo;
    if (color) {
        clasesBoton += DEFAULT_BOTON_CLASSNAME + ' ' + color;
    } else {
        clasesBoton += (darkMode && !color ? DARK_THEME_BUTTON : DEFAULT_THEME_BUTTON);
    }
    return (
        <button id={ide} className={(clasesBoton) + ' disabled:bg-tradewind-300' } onClick={onClick} disabled={disabled}>
            {texto}
            {icono && <FontAwesomeIcon icon={icono} />}
        </button>
    );
};

export default Boton;
