import React from 'react';
import InputDatePicker from '../components/inputDatePicker';

export default function CreateTurns () {
    return (
        <form className="py-12 flex flex-col gap-10" id="createTurnsSchedule">
            <div className="col flex flex-col">
                <InputDatePicker label={'Fecha inicio'} disabledPast={true}/>
            </div>
            <div className="col flex flex-col">
                <InputDatePicker label={'Fecha hasta'} disabledPast={true}/>
            </div>

        </form>
    );
}
