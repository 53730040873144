import { React, useState } from 'react';
import { AutoComplete } from '../components/autocomplete';
import { turnsConstants, DAYS, SCHEDULE_CELLS, EMPTY_SCHEDULE } from '../constants/constants';
import InputSelect from '../components/inputSelect';
import { getHorariosByProfesional, saveSchedules } from '../services/data.service';
import Boton from '../components/boton';
import { InputText } from '../components/inputText';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import EditTable from '../parts/EditTable';
import { errorPopup, warningPopup } from '../components/popup';
import { useProfessionalsActive } from '../hooks/useProfessionalsActive';
import { CreateNewFeriado } from '../parts/FeriadoInHorarios';
import { CreateTurnsPopUp } from '../parts/CreateTurnsInHorarios';
import { deleteHorario, horarioValidations, allInputsFilled } from '../logic/horarios';
import ShowFeriados from '../parts/showFeriados';

export const CreateHorarios = (pars) => {
    /** *************VARIABLES*********************/
    const { professionals } = useProfessionalsActive();
    const [schedule, setSchedule] = useState(EMPTY_SCHEDULE);
    const [tableSchedule, setTableSchedule] = useState([]);
    const [counter, setCounter] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);

    /** ***************HANDLERS*********************** */

    const saveSchedule = async () => {
        if (tableSchedule.length === 0) {
            errorPopup({ title: 'Error', text: 'No hay horarios cargados' });
            return;
        }
        warningPopup({
            title: 'Confirmar',
            text: '¿Deseas guardar los horarios?'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    saveSchedules(tableSchedule);
                    handleClearAll();
                }
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSchedule((prevSchedule) => ({
            ...prevSchedule,
            [name]: value
        }));
    };

    const handleAutoComplete = (idd) => {
        if (idd !== null && idd.length > 0) {
            setSchedule((prevSchedule) => ({
                ...prevSchedule,
                [idd[0].name]: idd[0].text
            }));
        }
    };

    const handleSelector = (event, opt) => {
        let fill;
        if (event.target.name === 'professional_id') {
            fill = {
                prof_cod: opt[0].codigo,
                nombre_profesional: opt[0].text
            };
            setLoadingTable(true);
            searchSchedule(opt);
        } else {
            fill = {
                dia: opt[0].id
            };
        }
        setSchedule((prevSchedule) => ({
            ...prevSchedule,
            ...fill
        }));
    };

    const handleCargar = () => {
        if (!allInputsFilled(schedule)) {
            errorPopup({ title: 'Debe rellenar todos los campos', text: '' });
            return false;
        }
        if (horarioValidations(schedule, tableSchedule)) {
            const row = { ...schedule }; // clone schedule
            setTableSchedule([...tableSchedule,
                {
                    ...row,
                    id: counter
                }
            ]);
            setCounter(counter + 1);
            setSchedule((prev) => ({
                ...prev,
                ...EMPTY_SCHEDULE
            }));
        }
    };

    const searchSchedule = async (profesional) => {
        const horarios = await getHorariosByProfesional(profesional[0].codigo);
        if (horarios !== undefined) {
            setTableSchedule(horarios);
            setCounter(horarios.length);
        }
        setLoadingTable(false);
    };

    const handleTableChange = (index, name, value) => {
        const updatedSchedule = [...tableSchedule];
        if (index >= 0 && index < updatedSchedule.length) {
            updatedSchedule[index][name] = value;
            setTableSchedule(updatedSchedule);
        } else {
            console.error('Índice fuera de rango.');
        }
    };

    const handleClearAll = () => {
        setTableSchedule([]);
        setCounter(0);
        setSchedule(EMPTY_SCHEDULE);
        setShowForm(false);
        setLoadingTable(false);
    };

    const onDelete = async (index) => {
        const scheduleToDelete = tableSchedule[index];
        const response = await deleteHorario(scheduleToDelete.horario_id);
        if (response) {
            setTableSchedule(tableSchedule.filter((_, i) => i !== index));
        }
    };

    return (
        <div className="p-16">
            <h2 className="text-3xl flex justify-center mt-5 mb-10 text-principal font-bold"><p>Crear horarios</p></h2>
            <section className="md:grid grid-cols-2">
                <div className={showForm && 'grid gap-5 w-full'}>
                    <InputSelect initialValue={schedule.nombre_profesional} pars={{ ide: 'professional_id', darkMode: pars.darkMode, label: 'Profesional' }} data={professionals} onChange={handleSelector}/>
                    {
                        showForm &&
                        <form className="grid gap-5 w-full">
                            <AutoComplete initialValue={schedule.desde} ide="desde" onChange={handleAutoComplete} label="Desde" darkMode={pars.darkMode} data={turnsConstants.schedule}/>
                            <AutoComplete initialValue={schedule.hasta} ide="hasta" onChange={handleAutoComplete} label="Hasta" darkMode={pars.darkMode} data={turnsConstants.schedule}/>
                            <InputSelect initialValue={schedule.dia} onChange={handleSelector} pars={{ ide: 'dia', darkMode: pars.darkMode, label: 'Día' }} data={DAYS}/>
                            <InputText maxLength={3} keyUpType={'only-number'} value={schedule.tiempo} onChange={handleChange} label="Tiempo entre turnos (en minutos)" darkMode={pars.darkMode} ide="tiempo"/>
                        </form>
                    }
                    <div className="buttonsForm flex justify-end">
                        {showForm
                            ? <Boton color={'bg-[#64747a] hover:bg-[#74747a]'} ide="showForm" texto={'Cerrar'} estilo={'mt-6 h-fit w-fit'} onClick={(e) => setShowForm(false) }/>
                            : <Boton ide="showForm" texto={'Nuevo horario  '} icono={faPlus} estilo={'mt-6 h-fit w-fit'} onClick={(e) => setShowForm(true) }/>
                        }
                        {showForm && <Boton ide="saveOnTable" texto={'Cargar en tabla'} estilo={'m-6 h-fit w-fit'} onClick={ handleCargar }/>}
                    </div>
                </div>
                <div className="col px-5">
                    <EditTable darkMode={pars.darkMode} handleDelete={onDelete} actionsColumn={true} loadingEnable={loadingTable} dataRows={tableSchedule} cells={SCHEDULE_CELLS} onChange={handleTableChange}/>
                    <p className={pars.darkMode ? 'text-gray-300 text-sm m-2' : 'text-gray-500 text-sm m-2'}>* Haga click en la tabla para editarla</p>
                    <div className="flex justify-between mt-2 gap-6">
                        <section id="optionsButtons" className="flex gap-6">
                            <CreateTurnsPopUp prof_cod={schedule.prof_cod} />
                            <CreateNewFeriado profesionales={professionals}/>
                            <ShowFeriados />
                        </section>
                        <Boton disabled={tableSchedule.length === 0} ide="saveSchedule" texto={'Guardar'} estilo={'mt-6 h-fit w-fit justify-self-end'} onClick={ saveSchedule }/>
                    </div>
                </div>
            </section>
        </div>
    );
};
