import React from 'react';
import { InputText } from '../components/inputText';

export default function PopupForgotPassword () {
    return (
        <form className="py-12 flex flex-col gap-10" id="forgotPasswordForm">
            <div className="col flex flex-col">
                <InputText ide="usuario" label={'Ingrese su usuario'} />
            </div>
            <div className="col flex flex-col">
                <InputText ide={'email'} label={'Ingrese su email'} />
            </div>
            <div className="col flex flex-col">
                <InputText type={'password'} ide={'password'} label={'Ingrese su nueva password'} />
            </div>
            <div className="col flex flex-col">
                <InputText type={'password'} ide={'confirm_password'} label={'Confirme su nueva password'} />
            </div>
        </form>
    );
}
