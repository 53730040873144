import React, { useState, useEffect } from 'react';
import { useProfessionalsActive } from '../hooks/useProfessionalsActive';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

export function ProfessionalCheckboxes ({ onChange, initialValue, darkMode }) {
    const { professionals } = useProfessionalsActive();
    const [selectedValue, setSelectedValue] = useState(initialValue);
    useEffect(() => {
        // Update selectedValue when initialValue changes
        setSelectedValue(initialValue);
    }, [initialValue]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        if (onChange) {
            const professional = professionals.find(professional => professional.codigo + '' === newValue);
            onChange(event, [professional]);
        }
    };

    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Seleccione un profesional</FormLabel>
            <RadioGroup className="grid grid-cols-3 gap-4" value={selectedValue} onChange={handleChange}>
                {professionals.map(professional => (
                    <FormControlLabel key={professional.codigo} value={professional.codigo} label={professional.text}
                        control={
                            <Radio sx={
                                {
                                    color: '#62babb',
                                    '&.Mui-checked': {
                                        color: darkMode ? '#ace3e1' : '#245d61'
                                    }
                                }
                            } />
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
