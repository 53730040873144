import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

export default function CustomTurns ({ open, onClose, onSave, fecha, professional, paciente }) {
    const [hora, setDesde] = useState('');

    const handleSave = () => {
        onSave({
            hora
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Está a punto de asignar un nuevo sobre turno.</DialogTitle>
            <DialogContent>
                <TextField
                    label="Hora"
                    value={hora}
                    type="time"
                    onChange={(e) => setDesde(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSave} color="primary" autoFocus>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
