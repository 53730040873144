import React, { useEffect } from 'react';
import { CSS_INPUT } from '../constants/const_tailwind';

export function TextArea ({ ide, className, label, darkMode, onChange, rows = 2, inputValue }) {
    const [value, setValue] = React.useState(inputValue);

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);
    const constante = (constant) => {
        return CSS_INPUT[constant];
    };

    const inputClass = 'p-4 rounded-md border focus:outline-none text-black ' + (darkMode ? constante('DARK_THEME_INPUT') : constante('DEFAULT_THEME_INPUT'));
    const labelClass = darkMode ? constante('DARK_THEME_LABEL') : constante('DEFAULT_THEME_LABEL');

    return (
        <div className={'flex flex-col m-3 ' + className}>
            <label className={(labelClass)} htmlFor={ide}>{label}</label>
            <textarea onChange={onChange} id={ide} name={ide} value={value} rows={rows} className={(inputClass)}></textarea>
        </div>
    );
}
