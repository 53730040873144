import { addPacienteConstants } from '../constants/constants';
import { postLocalidadService, postPacienteService } from '../services/data.service';
import { errorPopup } from '../components/popup';

export const constante = (constant) => {
    return addPacienteConstants[constant];
};

export function handleSaveLocation () {
    const inputsLocation = Array.from(document.getElementById('locationForm').getElementsByTagName('input'));
    if (inputsLocation.every((i) => i.value.trim() !== '')) {
        const localidad = {
            codigo: inputsLocation[0].value,
            ciudad: inputsLocation[1].value,
            provincia: inputsLocation[2].value
        };
        postLocalidadService(localidad);
    }
}

export function getFormattedPatient (userResult) {
    return {
        id: userResult.id,
        Nombre: userResult.Nombre,
        FechaNacimiento: userResult.FechaNacimiento,
        Genero: userResult.Genero,
        Direccion: userResult.Direccion,
        CodPos: userResult.CodPos,
        ciudad: userResult.ciudad,
        Telefono: userResult.Telefono,
        Celular: userResult.Celular,
        Mail: userResult.Mail,
        TipoDocumento: 1,
        NumDocumento: userResult.NumDocumento,
        Empres: userResult.Empres,
        Plan: userResult.Plan,
        PlanText: userResult.obra_social ? userResult.obra_social.codigo + ' - ' + userResult.obra_social.descripcion : '',
        Plan2: userResult.Plan2,
        Plan2Text: userResult.obra_social_2 ? userResult.obra_social_2.codigo + ' - ' + userResult.obra_social_2.descripcion : '',
        NumAfiliado: userResult.NumAfiliado,
        Cabecera: userResult.Cabecera,
        CabeceraText: userResult.MedCabeceraNombre,
        UltimaVisita: userResult.UltimaVisita,
        Antecedentes: userResult.Antecedentes
    };
}

export async function postPaciente (paciente) {
    let inputToValidate = '';
    const validatedInputs = Object.keys(paciente).every((i) => {
        const needsValidation = constante('VALID_INPUTS_PACIENT').includes(i);
        inputToValidate = i;
        return (!needsValidation || (needsValidation && paciente[i] !== ''));
    });
    if (validatedInputs) {
        paciente.Nombre = paciente.Nombre.toUpperCase();
        delete paciente.cabecera;
        delete paciente.PlanText;
        delete paciente.Plan2Text;
        paciente.Nombre = paciente.Nombre.split(',').join(' ');
        const response = await postPacienteService(paciente);
        if (response && response.status) {
            return response;
        } else {
            return false;
        }
    } else {
        errorPopup({
            title: 'Debe ingresar los datos obligatorios.',
            text: 'El campo ' + inputToValidate.split('_')[0] + ' se encuentra vacío'
        });
        return false;
    }
}
