import React, { useMemo } from 'react';
import logo from '../images/logo_recoleta.webp';
import { HEADER_GRADIENT_CLASSNAME, LI_ITEM_CLASSNAME } from '../constants/const_tailwind';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../components/dropdown';
import { ThemeProvider, createTheme } from '@mui/material';

export function Header ({ darkMode, toggleDarkMode, handleLogOut }) {
    const userData = JSON.parse(sessionStorage.getItem('nameProf'));
    const nameProf = userData ? userData.name : '';
    return (
        <header className={HEADER_GRADIENT_CLASSNAME + ' header flex w-100 justify-between items-center' }>
            <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} handleLogOut={handleLogOut} nameProf={nameProf}/>
            <img className="" src={logo} alt="logo"/>
        </header>
    );
}

function NavBar ({ darkMode, toggleDarkMode, handleLogOut, nameProf }) {
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light'
                },
                components: {
                    MuiMenuItem: {
                        styleOverrides: {
                            root: {
                                padding: 0
                            }
                        }
                    }
                }
            }),
        [darkMode]
    );

    const pacienteOptions = [
        {
            id: 1,
            text: 'Búsqueda',
            link: '/busqueda'
        }
    ];

    const profesionalOptions = [
        {
            id: 1,
            text: 'Nuevo profesional',
            link: '/nuevoProfesional'
        },
        {
            id: 3,
            text: 'Ver pacientes',
            link: '/pacientesPresentes'
        },
        {
            id: 2,
            text: 'Horarios',
            link: '/createHorario'
        }
    ];

    const clinicaOptions = [
        {
            id: 1,
            text: 'Turnos',
            link: '/turnos'
        },
        {
            id: 2,
            text: 'Obra Social',
            link: '/obraSocial'
        },
        {
            id: 3,
            text: 'Crear usuario',
            link: '/createUser'
        }
    ];
    return (
        <ThemeProvider theme={theme}>
            <aside className="w-full flex justify-between items-center">
                <nav>
                    <ul className="flex text-xl ps-12 gap-6">
                        <Dropdown title="Pacientes" options={pacienteOptions} />
                        <Dropdown title="Profesionales" options={profesionalOptions} />
                        <Dropdown title="Clínica" options={clinicaOptions} />
                    </ul>
                </nav>
                <div className="flex items-center">
                    <ul>
                        <li className="text-white/80 font-bold mx-3">{nameProf}</li>
                    </ul>
                    <nav>
                        <ul className="flex me-16 gap-6">
                            <li className={LI_ITEM_CLASSNAME + ' hover:bg-gray-400 hover:rounded-md'} onClick={ toggleDarkMode }>
                                { !darkMode && <FontAwesomeIcon className="text-xl text-white/80" icon={faMoon} />}
                                { darkMode && <FontAwesomeIcon className="text-xl text-white/80" icon={faSun} />}
                            </li>
                            <li className={LI_ITEM_CLASSNAME + ' hover:bg-gray-400 hover:rounded-md'} id="logOut" onClick={handleLogOut}><FontAwesomeIcon className="text-xl text-white/80" icon={faRightFromBracket} /></li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </ThemeProvider>
    );
}
