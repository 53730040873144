import React from 'react';
import InputDatePicker from '../components/inputDatePicker';
import { InputText } from '../components/inputText';
import InputSelect from '../components/inputSelect';
import { useProfessionalsActive } from '../hooks/useProfessionalsActive';

export default function CreateFeriado () {
    const { professionals } = useProfessionalsActive();
    return (
        <form className="py-12 flex flex-col gap-10" id="createFeriado">
            <div className="col flex flex-col">
                <InputText ide="titulo" label={'Título'}/>
            </div>
            <div className="col flex flex-col">
                <InputDatePicker ide="fecha" label={'Fecha'} disabledPast={true}/>
            </div>

            <div className="col flex flex-col">
                <InputText ide={'motivo'} label={'Descripción'}/>
            </div>
            <div className="col flex flex-col">
                <InputSelect ide="profesional" pars={{ darkMode: false, ide: 'profesionalFeriado', label: 'Profesional' }} data={professionals} />
                <p className="text-sm">* Déjelo vacio para asignarselo todos</p>
            </div>
        </form>
    );
}
