import { useEffect, useState } from 'react';
import { getAllProfesionalesService } from '../services/data.service';

export function useProfessionals () {
    const [professionals, setProfessionals] = useState([]);
    useEffect(() => {
        const getProfessionals = async () => {
            try {
                const profs = await getAllProfesionalesService();
                setProfessionals(profs);
            } catch (err) {
                console.log(err);
            }
        };
        getProfessionals();
    }, []);
    return { professionals };
}
