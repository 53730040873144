/* eslint-disable no-unused-vars */
import { React, useState } from 'react';
import { InputText } from '../components/inputText';
import Boton from '../components/boton';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../parts/Table';
import { getAllPacientesService, getSearchPacientesService, getPacienteById } from '../services/data.service';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components/loading';
import { transformSearchPatient } from '../logic/utils';

export function BusquedaPaciente ({ onSeleccionarPersona }) {
    const [divEnabled, setDivEnabled] = useState(false);
    const [pacientes, setPacientes] = useState([]);
    const [tablePacientes, setTablePacientes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        let pacientesService;
        if (inputValue === '') {
            pacientesService = await getAllPacientesService();
        } else {
            pacientesService = await getSearchPacientesService(inputValue);
        }
        setLoading(false);
        if (pacientesService.enabled) {
            setDivEnabled(true);
            delete pacientesService.pacientes.text;
            setPacientes(pacientesService.pacientes);
            setTablePacientes(pacientesService.pacientes.map((paciente) => transformSearchPatient(paciente)));
        } else {
            setTablePacientes(null);
        }
    };

    const handleEdit = async (tablaPaciente) => {
        setLoading(true);
        const pacienteNew = await getPacienteById(tablaPaciente.id);
        if (pacienteNew) {
            setLoading(false);
            localStorage.setItem('editPaciente', JSON.stringify(pacienteNew));
            window.open('/nuevo', '_blank', 'noreferrer');
        } else {
            setLoading(false);
            console.error('Error al navegar a /nuevo:');
        }
    };

    const cells = ['Paciente', 'Documento', 'Fecha de nacimiento', 'Sexo', 'Domicilio', 'Localidad', 'Obra social', 'Teléfono'];

    const onDeletePersona = (paciente) => {
        console.log(paciente);
        // TODO: Cancelar turno/pte
    };

    const handleHistorico = (paciente) => {
        try {
            navigate('/historico', { state: { paciente } });
        } catch (error) {
            console.error('Error al navegar a /historico:', error);
        }
    };

    return (
        <div className="p-16">
            <h2 className="text-3xl flex justify-center my-5 text-principal font-bold"><p>Búsqueda</p></h2>
            <form onSubmit={handleSearch} className="flex justify-center items-center mb-10">
                <InputText className="w-2/4" ide="search_input" value={inputValue} label="" onChange={(e) => setInputValue(e.target.value)}/>
                <Boton ide="searchButton" icono={faSearch} estilo={'h-fit'}/>
            </form>
            {divEnabled
                ? <div id="resultTable">
                    <CustomTable onEdit={handleEdit} onHistorico={handleHistorico} buttonRow={true} cells={cells} dataRows={tablePacientes} onSeleccionarPersona={onSeleccionarPersona} onDeletePersona={onDeletePersona}/>
                </div>
                : null
            }
            <Loading open={loading} />
        </div>
    );
}
