import React from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { LI_ITEM_CLASSNAME } from '../constants/const_tailwind';
import { Link } from 'react-router-dom';

export function Dropdown (props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="dropdown">
            <Button
                id={ props.title + '-button'}
                aria-controls={open ? props.title + '-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="text-white"
            >
                {props.title}
            </Button>
            <Menu
                id={props.title + '-menu'}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': props.title + '-button'
                }}
            >
                {props.options.map((opcion) =>
                    <MenuItem onClick={handleClose} key={opcion.id}>
                        <Link to={ opcion.link} className={LI_ITEM_CLASSNAME}>{opcion.text}</Link>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}
