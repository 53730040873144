import React, { useState, useEffect } from 'react';
import InputSelect from '../components/inputSelect';
import { TextArea } from '../components/inputTextArea';
import Boton from '../components/boton';
import { motivos, emptyHistorial } from '../constants/constants';
import { saveHistorial, validateHistorial } from '../logic/historial';
import { getProfesionalByCodigo } from '../services/data.service';
import { transformDate } from '../logic/utils';
import { errorPopup, warningPopup } from '../components/popup';

export function NewVisit ({ darkMode }) {
    const [form, setForm] = useState(() => {
        return {
            ...emptyHistorial,
            id_paciente: Number(localStorage.getItem('pacienteNV')),
            fecha: transformDate(new Date())
        };
    });

    useEffect(() => {
        const fetchProfessional = async () => {
            const codProf = JSON.parse(sessionStorage.getItem('user')).profCodToken;
            if (!codProf && codProf !== 0) return; // Si no hay código de profesional, no se hace nada
            const foundProfessional = await getProfesionalByCodigo(codProf);
            if (foundProfessional) {
                setForm({
                    ...form,
                    prof_cod: codProf
                });
            }
        };
        fetchProfessional();
    }, []);

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    const handleSave = async () => {
        const turnId = localStorage.getItem('turnNV');
        if (!turnId) return;
        if (!validateHistorial(form)) {
            errorPopup({
                title: 'Error',
                text: 'Todos los campos son obligatorios'
            });
            return;
        }
        warningPopup({
            title: '¿Desea guardar esta visita?',
            text: 'Al dar clic en aceptar, se guardaran los cambios realizados en la visita'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await saveHistorial(form, Number(turnId));
                    if (response) {
                        setTimeout(() => {
                            window.opener.postMessage({ type: 'reloadHC', data: true }, window.location.origin);
                            window.close();
                        }, 2000);
                    }
                } catch (error) {
                    console.log(error);
                    errorPopup({ title: 'Error', text: 'Hubo un error al guardar la visita.' });
                }
            }
        });
    };

    return (
        <div className='p-16'>
            <h1 className='text-3xl flex justify-center my-5 text-principal font-bold'>Nueva visita</h1>
            <section className='grid gap-4 mt-2' id='content'>
                <InputSelect initialValue={form.motivo} data={motivos} pars={{ darkMode: darkMode, ide: 'motivo', label: 'Motivo de visita' }} onChange={handleChange}/>
                <p></p>
                <TextArea onChange={handleChange} value={form.observ} rows={3} darkMode={darkMode} name='observ' ide='observ' label='Observaciones' />
            </section>
            <div className='flex justify-end my-3 me-3'>
                <Boton onClick={handleSave} texto='Guardar'/>
            </div>
        </div>
    );
}
