export const LI_ITEM_CLASSNAME = 'text-sm cursor-pointer py-[6px] px-[16px] w-full';
export const HEADER_GRADIENT_CLASSNAME = 'bg-gradient-to-r from-gray-500 via-gray-600 to-image';
export const DEFAULT_BOTON_CLASSNAME = ' py-2 px-4 rounded text-white font-bold';

export const DEFAULT_THEME_BUTTON = DEFAULT_BOTON_CLASSNAME + ' bg-tradewind-700 hover:bg-tradewind-900';
export const DARK_THEME_BUTTON = DEFAULT_BOTON_CLASSNAME + ' bg-tradewind-500 hover:bg-tradewind-400';

export const CSS_INPUT = {
    DARK_THEME_INPUT: 'border-gray-800 focus:ring focus:ring-gray-700',
    DEFAULT_THEME_INPUT: 'bg-tradewind-50 border-gray-300 focus:ring focus:ring-gray-300',
    DARK_THEME_LABEL: 'text-tradewind-100',
    DEFAULT_THEME_LABEL: 'text-tradewind-800'
};

export const COL_MD_6 = ' w-full md:w-1/2 px-4';
export const COL_MD_4 = ' w-full md:w-1/3 px-4';
export const COLOR_POPUP_PRIMARY = '#62babb';
export const COLOR_POPUP_CANCEL = '#64747a';
