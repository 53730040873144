import { React, useEffect, useState } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { Popups } from '../constants/constants';
// import { deletePopup } from '../components/popup';

function CustomTable ({ dataRows, onDeletePersona, cells, buttonRow, noResult, onEdit, onHistorico }) {
    const [anchorElMap, setAnchorElMap] = useState({});
    const [selectedRow, setSelectedRow] = useState(null);
    const [rows, setRows] = useState(dataRows || []);

    useEffect(() => {
        if (dataRows && dataRows.length > 0) {
            setRows(dataRows);
        } else {
            setRows([]);
        }
    }, [dataRows]);
    const handleMenuClick = (event, index) => {
        const updatedAnchorElMap = { ...anchorElMap };
        updatedAnchorElMap[index] = event.currentTarget;
        setAnchorElMap(updatedAnchorElMap);
        setSelectedRow(index);
    };

    const handleMenuClose = () => {
        setAnchorElMap({});
        setSelectedRow(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        if (onEdit !== undefined) {
            const selected = rows.find(row => row.id === selectedRow);
            onEdit(selected);
        }
    };

    // const handleDelete = () => {
    //     deletePopup(Popups.DELETE_PACIENTE)
    //         .then((result) => {
    //             if (result.isConfirmed) {
    //                 const row = rows.find(row => row.id === selectedRow);
    //                 const updatedRows = rows.length !== 1 ? rows.splice(row.id, 1) : [];
    //                 setRows(updatedRows);
    //                 onDeletePersona(row);
    //             }
    //         });
    //     handleMenuClose();
    // };

    const handleHistorico = () => {
        handleMenuClose();
        if (onHistorico !== undefined) {
            const selected = rows.find(row => row.id === selectedRow);
            onHistorico(selected);
        }
    };
    return (
        <TableContainer component={Paper} className="tableContainer">
            <Table>
                <TableHead>
                    <TableRow className='bg-tradewind-800'>
                        {cells && cells.map((cell) => (
                            <TableCell key={cell} className='text-tradewind-50' align="center">{cell}</TableCell>
                        ))}
                        {buttonRow ? <TableCell className='text-tradewind-50' align="center"></TableCell> : ''}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows && rows.length === 0
                        ? (
                            <TableRow key={'no-results'}>
                                <TableCell key={'no-results'} align='center' colSpan={cells ? cells.length : 0}>
                                    <Typography variant="h6" color="textSecondary">
                                        {noResult || 'No se encontraron resultados.'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                        : rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {
                                    Object.entries(row.data).map(([key, value]) => (
                                        <TableCell key={key} align="center" id={key + '-' + row.id}>{value}</TableCell>
                                    ))
                                }
                                {
                                    buttonRow
                                        ? (
                                            <TableCell>
                                                <IconButton
                                                    aria-label="Acciones"
                                                    aria-controls={`menu-${row.id}`}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={(e) => handleMenuClick(e, row.id)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id={`menu-${row.id}`}
                                                    anchorEl={anchorElMap[row.id]}
                                                    open={Boolean(anchorElMap[row.id])}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem className='py-[6px] px-[16px]' onClick={() => handleEdit()}>Editar</MenuItem>
                                                    <MenuItem className='py-[6px] px-[16px]' onClick={() => handleHistorico()}>Historial clínico</MenuItem>
                                                    {/* <MenuItem onClick={handleDelete}>Eliminar</MenuItem> */}
                                                </Menu>
                                            </TableCell>
                                        )
                                        : null
                                }
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CustomTable;
