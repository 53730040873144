import Autocomplete from '@mui/material/Autocomplete';
import { React, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Box, FormControl } from '@mui/material';
import { CSS_INPUT } from '../constants/const_tailwind';
import { transformData } from '../logic/utils';
export function AutoComplete (pars) {
    const { data, className, darkMode, onChange, ide, initialValue, clear, dinamic, onFetch } = pars;
    const [opt, setOpt] = useState('');
    const [options, setOptions] = useState(data);

    useEffect(() => {
        if (typeof initialValue !== 'undefined') {
            setOpt(initialValue);
        }
    }, [initialValue]);

    useEffect(() => {
        if (clear) {
            setOpt('');
        }
    }, [clear]);

    useEffect(() => {
        setOptions(data);
    }, [data]);
    const handleChange = (event, value) => {
        setOpt(value);
        if (onChange !== undefined) {
            const iddata = options.filter(d => d.text === value);
            if (value !== null) {
                iddata[0].name = ide;
            }
            onChange(iddata, value);
        }
    };

    const handleSearch = async (event) => {
        const value = event ? event.target.value : null;
        if (value === null) return;
        if (dinamic && value !== '' && value.length > 3) {
            const response = await onFetch(value);
            if (response && response.length !== 0) {
                setOptions(transformData(response.pacientes));
            }
        }
    };

    const dinamicAttributes = dinamic
        ? {
            filterOptions: (opt) => opt,
            getOptionLabel: (option) => option,
            onInputChange: (e) => handleSearch(e)
        }
        : {};
    return (
        <div className={'flex flex-col mx-3 ' + className}>
            <Box>
                <FormControl fullWidth size="small">
                    <label className={darkMode ? CSS_INPUT.DARK_THEME_LABEL : CSS_INPUT.DEFAULT_THEME_LABEL}>{pars.label}</label>
                    <Autocomplete
                        className=" max-h-44 bg-tradewind-50 rounded p-0"
                        disablePortal
                        noOptionsText="No hay resultados"
                        size="small"
                        autoHighlight={true}
                        value={opt}
                        id={ide}
                        options={options.map((option) => option.text)}
                        {...dinamicAttributes}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                </FormControl>
            </Box>
        </div>
    );
}
