import { AddProfesionalConstants, Popups } from '../constants/constants';
import { errorPopup, warningPopup } from '../components/popup';
import { postProfesionalService } from '../services/data.service';

export function constante (constant) {
    return AddProfesionalConstants[constant];
}

export function validateProfesional (profesional) {
    let isValid = true;
    Object.entries(profesional).forEach(([_, value]) => {
        if (value === '') {
            isValid = false;
        }
    });
    return isValid;
}

export function postProfesional (profesional) {
    if (!validateProfesional()) {
        errorPopup(Popups.ERROR_POPUP_PROFESIONAL);
        return false;
    }
    warningPopup(Popups.OPCIONES_POPUP_PROFESIONAL)
        .then((response) => {
            if (response.isConfirmed) {
                if (postProfesionalService(profesional)) {
                    return true;
                }
            } else {
                return false;
            }
        });
}
