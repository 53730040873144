import React, { useState } from 'react';
import { InputText } from '../components/inputText';
import Boton from '../components/boton';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { getUserByNamev2, createUser } from '../services/data.service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { errorPopup, warningPopup } from '../components/popup';

const emptyUser = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    prof_cod: ''
};

export default function Users ({ darkMode }) {
    const [user, setUser] = useState(emptyUser);
    const [showIcon, setShowIcon] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
        if (name === 'name') {
            searchUser(value);
        }
    };

    const searchUser = async () => {
        const json = await getUserByNamev2();
        if (json) {
            setShowIcon(true);
        }
    };

    const saveUser = () => {
        if (user.name === '' || user.email === '' || user.password === '' || user.password_confirmation === '') {
            errorPopup({ title: 'Error', text: 'Todos los campos son obligatorios' });
            return;
        }
        if (user.password !== user.password_confirmation) {
            errorPopup({ title: 'Error', text: 'Las contraseñas no coinciden' });
        } else {
            warningPopup({ title: 'Está a punto de crear un nuevo usuario.', text: '¿Está seguro de que quiere continuar?' })
                .then((response) => {
                    if (response.isConfirmed) {
                        createUser(user);
                        setUser(emptyUser);
                    }
                });
        }
    };

    return (
        <div className="p-16" >
            <h2 className="text-3xl flex justify-center my-10 text-principal font-bold">Crear nuevo usuario</h2>
            <div className="grid grid-cols-2 gap-5">
                <InputText className="px-32" onChange={handleChange} darkMode={darkMode} ide="name" label="Usuario" />
                <InputText type={'password'} onChange={handleChange} className="px-32" darkMode={darkMode} ide="password" label="Contraseña" />
                <InputText className="px-32" onChange={handleChange} darkMode={darkMode} ide="email" label="Email" />
                <InputText type={'password'} onChange={handleChange} className="px-32" darkMode={darkMode} ide="password_confirmation" label="Repetir contraseña" />
                <InputText className="px-32" onChange={handleChange} darkMode={darkMode} ide="prof_cod" label="Código profesional" />
            </div>
            <div className="grid grid-cols-2 px-36 mt-10">
                {user.name
                    ? <Box className="flex items-center gap-x-4">
                        {showIcon
                            ? (
                                <CheckCircleIcon fontSize="large" className="text-tradewind-500" style={{ opacity: 1, transition: 'opacity 0.2s ease-in-out' }} />
                            )
                            : (
                                <CircularProgress className="text-tradewind-500" />
                            )}
                        <label>  { showIcon ? 'Usuario disponible' : 'Chequeando usuario...' }</label>
                    </Box>
                    : <p></p>
                }
                <Boton onClick={saveUser} darkMode={darkMode} estilo={'w-fit justify-self-end'} texto="Crear usuario" />
            </div>
        </div>
    );
}
