import React, { useState } from 'react';
import { SEXO } from '../constants/constants';
import { InputText } from '../components/inputText';
import InputSelect from '../components/inputSelect';
import Boton from '../components/boton';
import InputDatePicker from '../components/inputDatePicker';
import { constante, postProfesional } from '../logic/profesional';
import { useProfessionals } from '../hooks/useProfesional';
import { warningPopup, errorPopup } from '../components/popup';
import { deleteProfesional } from '../services/data.service';

export function AddProfesional ({ darkMode }) {
    const [profesional, setProfesional] = useState(constante('EMPTY_PROFESIONAL'));
    const [profesionalDelete, setProfesionalDelete] = useState(-1);
    const { professionals } = useProfessionals();
    const [showDelete, setShowDelete] = useState(false);
    const handleSave = () => {
        if (postProfesional(profesional)) {
            setTimeout(() => {
                handleClearForm();
                window.location.reload();
            }, 4000);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfesional((prevProfesional) => ({
            ...prevProfesional,
            [name]: value
        }));
    };

    const handleDatePicker = (e) => {
        setProfesional((prevProfesional) => ({
            ...prevProfesional,
            FechaNacimiento: e.split('/').reverse().join('-')
        }));
    };

    const handleClearForm = () => {
        setProfesional(constante('EMPTY_PROFESIONAL'));
    };

    const handleChangeDelete = (_, opt) => {
        setProfesionalDelete(opt[0].codigo);
    };

    const handleDelete = () => {
        warningPopup({
            title: 'Eliminar Profesional',
            text: '¿Estás seguro de eliminar este profesional?',
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(248 113 113)'
        })
            .then((response) => {
                if (response.isConfirmed) {
                    if (profesionalDelete === -1) {
                        errorPopup({ title: 'Error', text: 'Debe seleccione un profesional.' });
                    } else {
                        deleteProfesional(profesionalDelete);
                        console.log(profesionalDelete);
                    }
                }
            });
    };

    return (
        <div className="p-16">
            <h2 className="text-3xl flex justify-center my-10 text-principal font-bold"><p>Crear nuevo profesional</p></h2>
            <section id="section-validation" className="grid grid-cols-3 gap-5">
                <InputText onChange={handleChange} keyUpType="only-number" darkMode={darkMode} ide="Codigo" value={profesional.Codigo} name="Codigo" label="Código *"/>
                <InputText onChange={handleChange} keyUpType="only-text" darkMode={darkMode} ide="Nombre" value={profesional.Nombre} name="Nombre" label="Nombre/s *"/>
                <InputText onChange={handleChange} keyUpType="only-text" darkMode={darkMode} ide="Apellido" value={profesional.Apellido} name="Apellido" label="Apellido/s *"/>
                <InputText placeholder="Sin puntos" onChange={handleChange} keyUpType="only-number" darkMode={darkMode} ide="NumDocumento" value={profesional.NumDocumento} name="NumDocumento" label="DNI *"/>
                <InputText onChange={handleChange} keyUpType="only-number" darkMode={darkMode} ide="Telefono" value={profesional.Telefono} name="Telefono" label="Teléfono *"/>
                <InputDatePicker disabledFuture={true} onChange={handleDatePicker} darkMode={darkMode} ide="FechaNacimiento" initialValue={profesional.FechaNacimiento} name="FechaNacimiento" label="Fecha de nacimiento *"/>
                <InputSelect initialValue={profesional.Genero} onChange={handleChange} data={SEXO} pars={{ ide: 'Genero', label: 'Género *', darkMode }}/>
                <InputText onChange={handleChange} darkMode={darkMode} ide="Matricula" value={profesional.Matricula} name="Matricula" label="Matrícula"/>
                <InputText onChange={handleChange} keyUpType="only-number" darkMode={darkMode} ide="Cuit" value={profesional.Cuit} name="Cuit" label="CUIT"/>
                <InputText onChange={handleChange} darkMode={darkMode} ide="Mail" type={'email'} value={profesional.Mail} name="Mail" label="Correo electrónico *"/>
                <InputText onChange={handleChange} darkMode={darkMode} ide="Direccion" name="Direccion" value={profesional.Direccion} label="Domicilio"/>
                {/* <InputSelect onChange={handleChange} data={constante("CATEGORIAS")} profesional={{ ide:"Categoria" initialValue={profesional.Categoria}, label: 'Categoría',darkMode} }/>  */}
            </section>
            <div className="w-full mt-12 pe-3 gap-3 flex justify-between">
                {
                    showDelete
                        ? <div id="profesionalDelete" className="w-full">
                            <div className="w-full grid grid-cols-9">
                                <div className='col-span-3'>
                                    <InputSelect pars={{ darkMode: darkMode, ide: 'delete_profesional', label: 'Seleccione un profesional' }} data={professionals} onChange={handleChangeDelete}/>
                                </div>
                                <Boton texto="Eliminar" estilo="h-fit self-end w-fit" onClick={handleDelete} color="bg-red-400 hover:bg-red-500 text-white" />
                            </div>
                        </div>
                        : <Boton texto="Dar de baja un profesional" onClick={() => setShowDelete(!showDelete)} color="bg-red-400 hover:bg-red-500 text-white" />
                }
                <div className="flex gap-3">
                    <Boton ide="clearButton" texto="Limpiar" color="bg-secondary hover:bg-gray-700 h-fit" onClick={handleClearForm}></Boton>
                    <Boton ide="saveButton" texto="Guardar" estilo="h-fit" onClick={handleSave}></Boton>
                </div>
            </div>
        </div>
    );
}
