import React, { useEffect, useState } from 'react';
import { InputText } from '../components/inputText';
import { TextArea } from '../components/inputTextArea';
import Boton from '../components/boton';
import { SEXO, Popups } from '../constants/constants';
import InputSelect from '../components/inputSelect';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { constante, handleSaveLocation, getFormattedPatient, postPaciente } from '../logic/paciente';
import { formPopup, warningPopup } from '../components/popup';
import { AutoComplete } from '../components/autocomplete';
import InputDatePicker from '../components/inputDatePicker';
import { useLocalidades } from '../hooks/useLocalidades';
import { useObraSociales } from '../hooks/useObraSociales';
import { useProfessionals } from '../hooks/useProfesional';
import { useProfessionalsActive } from '../hooks/useProfessionalsActive';
import { useLocation, useNavigate } from 'react-router-dom';

export function AddPaciente ({ darkMode, setPersonaSeleccionada }) {
    const [paciente, setPaciente] = useState(constante('emptyInputs'));
    const { professionals: allProfessionals } = useProfessionals();
    const { professionals: activeProfessionals } = useProfessionalsActive();
    const [clear, setClear] = useState(false);
    const { localidades } = useLocalidades();
    const { obrasociales } = useObraSociales();
    const [isEdit, setIsEdit] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    /* ******************* Hooks **************** */
    useEffect(() => {
        let editPaciente = JSON.parse(localStorage.getItem('editPaciente'));
        if (editPaciente !== null) {
            editPaciente = getFormattedPatient(editPaciente);
            localStorage.removeItem('editPaciente');
            setPaciente(editPaciente);
            setIsEdit(true);
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            if (typeof location.state.NombreDoc === 'string') {
                setPaciente({
                    ...paciente,
                    Nombre: location.state.NombreDoc
                });
            } else {
                setPaciente({
                    ...paciente,
                    NumDocumento: location.state.NombreDoc
                });
            }
        }
    }, [location]);

    /* ******************* Handlers **************** */
    const handleShowPopup = () => {
        warningPopup(Popups.OPCIONES_POPUP_PACIENTE)
            .then((response) => {
                if (response.isConfirmed) {
                    savePatient();
                }
            });
    };

    const savePatient = async () => {
        const serviceResponse = await postPaciente(paciente);
        if (serviceResponse.status) {
            handleClearForm();
            localStorage.setItem('newPaciente', JSON.stringify(serviceResponse.paciente));
            navigate('/turnos');
        }
    };

    const handleAddLocation = () => {
        formPopup(Popups.ADD_LOCACION)
            .then((response) => {
                if (response.isConfirmed) handleSaveLocation();
            });
    };

    const handleChange = (e, opt) => {
        const { name, value } = e.target;
        if (name === 'Cabecera') {
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                CabeceraText: opt[0].text,
                Cabecera: opt[0].codigo
            }));
            return;
        }
        if (name === 'CodPos') {
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                CodPos: opt[0].codigo + '',
                ciudad: opt[0].text
            }));
            return;
        }
        setPaciente((prevPaciente) => ({
            ...prevPaciente,
            [name]: value
        }));
    };

    const handleDatePicker = (date) => {
        setPaciente((prevPaciente) => ({
            ...prevPaciente,
            FechaNacimiento: date
        }));
    };

    const handleObraSocial = (opc) => {
        if (opc.length > 0) {
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                Plan: opc[0].codigo,
                PlanText: opc[0].text
            }));
        }
    };

    const handleObraSocial2 = (opc) => {
        if (opc.length > 0) {
            setPaciente((prevPaciente) => ({
                ...prevPaciente,
                Plan2: opc[0].codigo,
                Plan2Text: opc[0].text
            }));
        }
    };

    const handleClearForm = () => {
        setPaciente(constante('emptyInputs'));
        setPersonaSeleccionada(null);
        setClear(true);
    };
    return (
        <div className="p-16">
            <h2 className="text-3xl flex justify-center my-5 text-principal font-bold"><p>Crear nuevo paciente</p></h2>
            <h2 className={('text-xl my-5 ' + (darkMode ? 'text-white' : 'text-secondary'))}>Datos personales</h2>
            <section id="section-validation" className="grid grid-cols-3 gap-5">
                <InputText placeholder='Pérez Fernando' keyUpType="only-text" onChange={handleChange} darkMode={darkMode} ide="Nombre" name="Nombre" value={paciente.Nombre} label={constante('LABEL_NOMBRE_INPUT')}/>
                <InputText keyUpType="only-number" onChange={handleChange} darkMode={darkMode} ide="NumDocumento" name="NumDocumento" value={paciente.NumDocumento} label={constante('LABEL_DNI_INPUT')}/>
                <InputText keyUpType="only-number" onChange={handleChange} darkMode={darkMode} ide="Telefono" name="Telefono" value={paciente.Telefono} label={constante('LABEL_TELEFONO_INPUT')}/>
                <InputText keyUpType="only-number" onChange={handleChange} darkMode={darkMode} ide="Celular" name="Celular" value={paciente.Celular} label={constante('LABEL_CELULAR_INPUT')}/>
                <InputDatePicker disabledFuture={true} initialValue={paciente.FechaNacimiento} onChange={handleDatePicker} darkMode={darkMode} ide="FechaNacimiento" name="FechaNacimiento" value={paciente.FechaNacimiento} label={constante('LABEL_FNAC_INPUT')}/>
                <InputSelect onChange={handleChange} initialValue={paciente.Genero} data={SEXO} pars={{ label: 'Género', darkMode, ide: 'Genero' }}/>
                <InputText onChange={handleChange} darkMode={darkMode} ide="Mail" name="Mail" value={paciente.Mail} label={constante('LABEL_EMAIL_INPUT')}/>
                <InputText onChange={handleChange} darkMode={darkMode} ide="Direccion" name="Direccion" value={paciente.Direccion} label={constante('LABEL_DIRECCION_INPUT')}/>
                <div className="flex items-center">
                    <div className="w-11/12">
                        <InputSelect onChange={handleChange} initialValue={paciente.ciudad} data={localidades} pars={{ label: 'Localidad', darkMode, ide: 'CodPos' }}/>
                    </div>
                    <div className="w-1/12 md:self-end mb-1">
                        <Boton ide="addLocation" onClick={handleAddLocation} icono={faPlus} estilo={'h-fit'}/>
                    </div>
                </div>
            </section>
            <h2 className={('text-xl my-5 ' + (darkMode ? 'text-white' : 'text-secondary'))}>Obra social</h2>
            <section className="grid grid-cols-3 gap-5">
                <AutoComplete initialValue={paciente.PlanText} clear={clear} onChange={handleObraSocial} data={obrasociales} label='Obra social principal' darkMode={darkMode} ide="PlanText" />
                <AutoComplete initialValue={paciente.Plan2Text} clear={clear} onChange={handleObraSocial2} data={obrasociales} label={constante('LABEL_OSSECUNDARIA_INPUT')} darkMode={darkMode} ide="Plan2Text" />
                <InputText onChange={handleChange} darkMode={darkMode} ide="Empres" name="Empres" value={paciente.Empres} label={constante('LABEL_PLAN_INPUT')}/>
                <InputText onChange={handleChange} darkMode={darkMode} ide="NumAfiliado" name="NumAfiliado" value={paciente.NumAfiliado} label={constante('LABEL_NUM_AFILIADO_INPUT')}/>
                <InputSelect onChange={handleChange} initialValue={paciente.CabeceraText} data={isEdit === true ? allProfessionals : activeProfessionals} pars={{ label: constante('LABEL_PROF_INPUT'), darkMode, ide: 'Cabecera' }}/>
                <InputText disabled={true} onChange={handleChange} darkMode={darkMode} ide="ultimaVisita_input" name="ultimaVisita_input" value={paciente.UltimaVisita} label={constante('LABEL_ULTIMAVISITA_INPUT')}/>
            </section>
            <section className="flex">
                <div className="w-2/3">
                    <TextArea onChange={handleChange} darkMode={darkMode} ide="Antecedentes" name="Antecedentes" inputValue={paciente.Antecedentes} label={constante('LABEL_ANTECEDENTES_INPUT')} />
                </div>
                <div className="w-1/3 flex justify-end self-end pe-3 gap-3">
                    <Boton ide="clearButton" texto="Limpiar" color="bg-secondary hover:bg-gray-700" onClick={handleClearForm}></Boton>
                    <Boton ide="saveButton" texto="Guardar" onClick={handleShowPopup}></Boton>
                </div>
            </section>
        </div>
    );
}
