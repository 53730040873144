import React from 'react';
import { formPopup } from '../components/popup';
import PopupForgotPassword from '../parts/PopupForgotPassword';

export function ForgotPassword () {
    const handleForgotPass = () => {
        formPopup({
            title: '¿Ha olvidado su contraseña?',
            form: <PopupForgotPassword />
        }).then((response) => {
            if (response.isConfirmed) {
                const inputs = Object.entries(window.document.getElementById('forgotPasswordForm').getElementsByTagName('input')).map(
                    (i, _) => {
                        return {
                            [i[1].id]: i[1].value
                        };
                    });
                const notEmpty = inputs.every((i) => i.value.trim() !== '');
                if (notEmpty) {
                    console.log(inputs);
                }
            }
        });
    };

    return (
        <span className="text-sm text-tradewind-900 mt-4 text-center" onClick={handleForgotPass}>
            <p className="hover:underline cursor-pointer">¿Ha olvidado su contraseña?</p>
        </span>
    );
}
