import { postHistorialClinico } from '../services/data.service';

export async function saveHistorial (form, turnId) {
    try {
        form.observ = form.motivo + ': ' + form.observ;
        delete form.motivo;
        const response = await postHistorialClinico(form, turnId);
        return response;
    } catch (error) {
        console.log('historial.js: ' + error);
        return false;
    }
}

export function validateHistorial (form) {
    return Object.entries(form).every((i) => i[1] !== '');
}
