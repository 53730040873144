import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const popup = withReactContent(Swal);
const primaryColor = '#1f3f42';
const secondaryColor = '#62babb';

export function successPopup (title = '¡Guardado con éxito!') {
    return popup.fire({
        icon: 'success',
        title: title,
        timer: '3000',
        showConfirmButton: false
    });
}

export function errorPopup ({ title, text, html = null }) {
    return popup.fire({
        icon: 'error',
        title: title,
        text: text,
        html: html,
        showConfirmButton: true,
        confirmButtonColor: primaryColor,
        confirmButtonText: 'Aceptar'
    });
}

export function warningPopup ({ title, text, confirmButtonText = 'Guardar', confirmButtonColor = primaryColor }) {
    return popup.fire({
        title: <strong>{`${title}`}</strong>,
        html: <span>{`${text}`}</span>,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: confirmButtonColor,
        confirmButtonText: confirmButtonText,
        showDenyButton: true,
        denyButtonColor: secondaryColor,
        denyButtonText: 'Cancelar',
        customClass: {
            cancelButton: 'order-1',
            confirmButton: 'order-2'
        }
    });
}

export function deletePopup ({ title, text }) {
    return popup.fire({
        title: <strong>{`${title}`}</strong>,
        html: <span>{`${text}`}</span>,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#FE2E2E',
        confirmButtonText: 'Eliminar',
        showDenyButton: true,
        denyButtonColor: secondaryColor,
        denyButtonText: 'Cancelar',
        customClass: {
            cancelButton: 'order-1',
            confirmButton: 'order-2'
        }
    });
}

export function formPopup ({ title, form, colorConfirm = primaryColor, textConfirm = 'Guardar' }) {
    return popup.fire({
        title: title,
        html: form,
        showConfirmButton: true,
        confirmButtonColor: colorConfirm,
        confirmButtonText: textConfirm,
        showDenyButton: true,
        denyButtonColor: secondaryColor,
        denyButtonText: 'Cancelar',
        customClass: {
            cancelButton: 'order-1',
            confirmButton: 'order-2'
        }
    });
}
