/* eslint-disable no-unused-vars */
import { putObraSocial, getTurnsByDateProfessionalLibre, getTurnsByDateProfessional } from '../services/data.service';

export const tableColumns = [
    { field: 'codigo', headerName: 'Codigo', editable: false },
    { field: 'descripcion', headerName: 'Descripcion', editable: true },
    {
        field: 'activa',
        headerName: 'Activa',
        options: {
            type: 'checkbox',
            onChange: handleCheckboxChange
        }
    },
    { field: 'orden', headerName: 'Orden', editable: true },
    { field: 'maxAnual', headerName: 'Maximo Anual', editable: true },
    { field: 'maxMensual', headerName: 'Maximo Mensual', editable: true },
    { field: 'vigencia', headerName: 'Vigencia', editable: true },
    { field: 'mensaje1', headerName: 'Mensaje', editable: true }
];

export async function handleCheckboxChange (event, name, row) {
    const response = await putObraSocial(row.id, { [name]: event.target.checked });
    try {
        console.log(response);
    } catch (err) {
        console.error(err);
        return [];
    }
}
