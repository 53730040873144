import React, { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider, esES } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';
import { CSS_INPUT } from '../constants/const_tailwind';
import { transformDate } from '../logic/utils';
export default function InputDatePicker ({ onChange, darkMode, label, disabledPast, disabledFuture, initialValue }) {
    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);
            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    useEffect(() => {
        if (!initialValue) {
            clearDatePicker();
        }
    }, [initialValue]);

    const clearDatePicker = () => {
        setCleared(true);
    };

    const handleAccept = (selectedDate) => {
        const transformedDate = selectedDate ? transformDate(selectedDate) : '';
        if (onChange !== undefined) {
            onChange(transformedDate);
        }
    };

    const handleChange = (date) => {
        const transformedDate = transformDate(date);
        const dateLength = transformedDate.split('-')[0].length !== 4;
        if (transformedDate === 'Invalid Date' || dateLength) {
            return;
        }
        if (onChange !== undefined) {
            onChange(transformedDate);
        }
    };

    return (
        <div className={'flex flex-col mx-3'}>
            <label className={darkMode ? CSS_INPUT.DARK_THEME_LABEL : CSS_INPUT.DEFAULT_THEME_LABEL}>{label}</label>
            <LocalizationProvider
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
                className={'max-h-60'}
            >
                <DatePicker
                    className={'bg-tradewind-50 text-black rounded'}
                    onAccept={handleAccept}
                    onChange={handleChange}
                    slotProps={{
                        field: { clearable: true, onClear: () => clearDatePicker() }
                    }}
                    value={initialValue ? new Date(initialValue.replace(/-/g, '/')) : null}
                    disablePast= {!!disabledPast}
                    disableFuture= {!!disabledFuture}
                    sx={{
                        '& .MuiInputBase-input': {
                            padding: '9px 14px'
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
}
