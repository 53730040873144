import axios from 'axios';
import { CONFIG } from '../constants/const_services';

CONFIG.headers.Authorization = sessionStorage.getItem('user') !== null ? 'Bearer ' + (JSON.parse(sessionStorage.getItem('user'))).token : '';

export function get (url, needConfig = true) {
    return needConfig ? axios.get(url, CONFIG).catch(error => checkAuth(error)) : axios.get(url);
}

export function post (url, data, needConfig = true) {
    if (needConfig) {
        data.usuario = (JSON.parse(sessionStorage.getItem('user'))).userName;
    }
    return needConfig ? axios.post(url, data, CONFIG) : axios.post(url, data);
}

export function put (url, data, needConfig = true, multiple = false) {
    if (needConfig && !multiple) {
        data.usuario = (JSON.parse(sessionStorage.getItem('user'))).userName;
    }
    if (multiple) {
        data = data.map((d) => ({ ...d, usuario: (JSON.parse(sessionStorage.getItem('user'))).userName }));
    }
    return needConfig ? axios.put(url, data, CONFIG) : axios.put(url, data);
}

export function deleteService (url, data, needConfig = true) {
    return needConfig ? axios.delete(url, CONFIG) : axios.delete(url);
}

export function setAuthHeader (token) {
    CONFIG.headers.Authorization = 'Bearer ' + token;
}

function checkAuth (err) {
    if (err.response.status === 401 && err.response.data.message === 'Unauthenticated.') {
        console.log('entro');
        localStorage.clear();
        window.location.href = '/';
    }
    throw err;
}
