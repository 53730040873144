/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { saveTurnsFromSchedule } from '../services/data.service';
import Boton from '../components/boton';
import { formPopup } from '../components/popup';
import { Popups } from '../constants/constants';

export function CreateTurnsPopUp (pars) {
    const { prof_cod } = pars;
    const [profesional, setProfesional] = useState(prof_cod);
    useEffect(() => {
        if (prof_cod) {
            setProfesional(prof_cod);
        }
    }, [pars]);

    const handleAddTurns = () => {
        formPopup(Popups.CREATE_TURNS)
            .then((response) => {
                if (response.isConfirmed) {
                    const inputs = Array.from(document.getElementById('createTurnsSchedule').getElementsByTagName('input'));
                    const notEmpty = inputs.every((i) => i.value.trim() !== '');
                    if (notEmpty && profesional) {
                        const scheduleTurns = {
                            prof_cod: profesional,
                            fechaInicio: inputs[0].value.split('/').reverse().join('-'),
                            fechaFin: inputs[1].value.split('/').reverse().join('-')
                        };
                        saveTurnsFromSchedule(scheduleTurns);
                    }
                }
            });
    };

    return (
        <Boton disabled={profesional === undefined} color={'bg-gray-600 hover:bg-gray-700 disabled:bg-gray-300'} ide="saveTurns" texto={'Crear turnos'} estilo={'mt-6 h-fit w-fit justify-self-end'} onClick={ handleAddTurns }/>
    );
}
