import { React, useState, useEffect } from 'react';
import { InputText } from '../components/inputText';
import Boton from '../components/boton';
import logo from '../images/logo_recoleta.webp';
import { Loading } from '../components/loading';
import { ForgotPassword } from '../parts/ForgotPassword';

const LoginForm = ({ onLogin, error }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [sesion, setSesion] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [error]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            email: email,
            password: password,
            sesion: sesion
        };
        const response = await onLogin(data);
        if (response) setLoading(false);
    };

    return (
        <div className="min-h-screen bg-gradient-to-l from-slate-700 to-stone-100 flex items-center justify-center">
            <div className="rounded-lg shadow-2xl p-8 md:p-16 grid grid-cols-2 gap-8">
                <section className='flex items-center justify-center'>
                    <img src={logo} alt="logo" width={'350'} />
                </section>
                <section className='flex flex-col justify-center'>
                    <h2 className="text-3xl font-bold mb-6 text-center text-tradewind-900">Inicio de Sesión</h2>
                    <form onSubmit={handleLogin}>
                        <div className="mb-6">
                            <InputText ide="email" type={'email'} label="Email" onChange={(e) => setEmail(e.target.value)}/>
                            <InputText ide="password" type={'password'} label="Contraseña" onChange={(e) => setPassword(e.target.value)}/>
                            <input id="sessionAlive" type="checkbox" className='m-3 cursor-pointer' onClick={(e) => setSesion(!sesion)}/>
                            <label htmlFor='sessionAlive' className='text-sm text-tradewind-900' >Mantener la sesión iniciada</label>
                            {error && <p className='flex justify-center text-red-600'>Email o contraseña incorrectos.</p>}
                        </div>
                        <div className='text-center'>
                            <Boton texto="Iniciar Sesión" type="submit" />
                        </div>
                    </form>
                    <ForgotPassword />
                    {loading &&
                        <Loading open={loading} login={true}/>
                    }
                </section>
            </div>
        </div>
    );
};

export default LoginForm;
