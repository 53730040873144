import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Boton from '../components/boton';
import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import { getHistorialClinico, putHC } from '../services/data.service';
import { calculateAge } from '../logic/utils';

export default function HistorialClinico () {
    const sessionPatient = JSON.parse(localStorage.getItem('pacienteHC'));
    const [historial, setHistorial] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editObsv, setEditObsv] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [paciente] = useState(location.state !== null ? location.state.paciente.data : sessionPatient.data);
    const [pacienteId] = useState(location.state !== null ? location.state.paciente.id : sessionPatient.id);
    const printRef = useRef();

    useEffect(() => {
        getHistorial(pacienteId);
    }, [pacienteId]);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin === window.location.origin && event.data.type === 'reloadHC') {
                navigate('/pacientesPresentes');
            }
        };
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    // localStorage.removeItem('pacienteHC');
    const getHistorial = async (idPaciente) => {
        const response = await getHistorialClinico(idPaciente);

        setHistorial(response);
    };

    const handleVisit = () => {
        localStorage.setItem('pacienteNV', pacienteId);
        localStorage.setItem('turnNV', location.state ? location.state.turnId : null);
        const newVisitTab = window.open('/nuevaVisita', '_blank');

        const sendReloadMessage = () => {
            if (newVisitTab && newVisitTab.postMessage) {
                newVisitTab.postMessage({ type: 'reloadHC' }, window.location.origin);
            } else {
                console.log('No se pudo enviar el mensaje a la nueva visita');

                setTimeout(sendReloadMessage, 100);
            }
        };
        sendReloadMessage();
    };

    const handleEditable = (event) => {
        setEditObsv(event.target.innerText);
    };

    const handlePutHc = async (event) => {
        setIsEditing(false);
        await putHC(event.target.id, { observ: editObsv });
    };

    return (
        <div className="p-16">
            <h2 className="text-3xl flex justify-center my-5 text-principal font-bold"><p>Historial clínico</p></h2>
            <section id='buttons' className="flex gap-4">
                <ReactToPrint
                    bodyClass='content'
                    content={() => printRef.current}
                    trigger={() => <Boton texto={'Imprimir '} icono={faPrint} color={'bg-slate-600 text-white hover:bg-slate-700'}/>}
                    pageStyle={'@page { size: A4; margin: 3xl; }'}
                />
                <Boton texto={'Nueva visita '} icono={faPlus} onClick={handleVisit}/>
            </section>
            <div className='content' ref={printRef}>
                <section className="grid grid-cols-2 gap-4">
                    <fieldset className="p-4 border border-gray-300 rounded-md shadow-md shadow-slate-400">
                        <legend className="text-xl font-semibold mb-2 p-2">
                            Datos del paciente
                        </legend>
                        <div className="p-6 flex flex-col gap-3">
                            <p>
                                Nombre: <strong> {paciente.paciente !== undefined ? paciente.paciente : '-'} </strong>
                            </p>
                            <p>
                                Documento: <strong> {paciente.documento !== undefined ? paciente.documento : '-'} </strong>
                            </p>
                            <p>
                                Localidad: <strong> {paciente.localidad !== undefined ? paciente.localidad : '-'} </strong>
                            </p>
                            <p>
                                Domicilio: <strong> {paciente.domicilio !== undefined ? paciente.domicilio : '-'} </strong>
                            </p>
                            <p>
                                Teléfono: <strong> {paciente.telefono !== undefined ? paciente.telefono : '-'} </strong>
                            </p>
                            <p>
                                Obra social: <strong> {paciente.obraSocial !== undefined ? paciente.obraSocial : '-'} </strong>
                            </p>
                            <p>
                                Fecha de nacimiento: <strong> {paciente.fechaNacimiento !== undefined ? paciente.fechaNacimiento : '-'} </strong>
                            </p>
                            <p>
                                Edad: <strong> {paciente.fechaNacimiento !== undefined ? calculateAge(paciente.fechaNacimiento) : '-'} </strong>
                            </p>
                            <p>
                                Sexo: <strong> {paciente.sexo !== undefined && paciente.sexo !== '' ? paciente.sexo : '-'} </strong>
                            </p>
                        </div>
                    </fieldset>
                    <fieldset className="p-4 border border-gray-300 rounded-md shadow-md shadow-slate-400">
                        <legend className="text-xl font-semibold mb-2 p-2">
                            Antecedentes
                        </legend>
                        <div className="p-6 flex flex-col gap-3">
                            {/* TODO: EN ANTECEDENTES DEBERÍA TRAERLO EL BACK ? */}
                            {paciente.antecedentes !== undefined && paciente.antecedentes !== '' ? paciente.antecedentes : '-'}
                        </div>
                    </fieldset>
                </section>
                <section className="grid grid-cols-1 gap-4" id="historial">
                    {historial && historial.map((h) => (
                        <fieldset key={h.id} className="p-4 border border-gray-300 rounded-md shadow-md shadow-slate-400">
                            <legend className="text-xl font-semibold mb-2 p-2">
                                {h.fecha.split('-').reverse().join('/')}
                            </legend>
                            <div className="p-6 flex flex-col gap-3">
                                <p><strong>Profesional:</strong> {h.profesional.Apellido} {h.profesional.Nombre}</p>
                                <span>
                                    <strong>Observaciones:</strong>
                                    <p
                                        id={h.id}
                                        contentEditable
                                        onInput={handleEditable}
                                        onBlur={handlePutHc}
                                        onClick={() => setIsEditing(true)}
                                        className={`just-line-break ${isEditing ? 'outline-none shadow-md bg-white p-2 text-black' : ''}`}
                                    > {h.observ} </p>
                                </span>
                            </div>
                        </fieldset>
                    ))}
                    { !historial && <h2 className='text-2xl flex my-5 italic '>No hay historial clínico para este paciente...</h2> }
                </section>
            </div>
        </div>
    );
}
