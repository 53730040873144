const baseURL = 'https://endpoint.centrooftalmorecoleta.net/api/';

export const URLS = {
    LOGIN_URL: baseURL + 'login',
    PACIENTE_POST_URL: baseURL + 'paciente/createPaciente',
    PROFESIONAL_POST_URL: baseURL + 'profesional/createProfesional',
    TURN_POST_URL: baseURL + 'turno/createTurno',
    INDIVIDUAL_TURN_POST_URL: baseURL + 'turno/createTurnoIndividual',
    USER_POST_URL: baseURL + 'user/createUser',
    SCHEDULE_POST_URL: baseURL + 'horario/createHorario',
    LOCALIDAD_POST_URL: baseURL + 'cp/createCodigoPostal',
    FERIADO_POST_URL: baseURL + 'feriado/createFeriado',
    HISTORIAL_CLINICO_POST_URL: baseURL + 'historiaclinica/create',
    GET_HISTORIAL_CLINICO: baseURL + 'historiaclinica/paciente/',
    GET_ALL_PACIENTES: baseURL + 'paciente/all',
    GET_PACIENTE_BY_ID: baseURL + 'paciente/',
    GET_PACIENTE_SEARCH: baseURL + 'paciente/searchPaciente',
    GET_ALL_PROFESIONALES: baseURL + 'profesional/all',
    GET_ALL_PROFESIONALES_ACTIVOS: baseURL + 'profesional/allActivos',
    GET_CP_URL: baseURL + 'cp/all',
    GET_TURNO_BY_DATE: baseURL + 'turno/date',
    GET_TURNO_BY_DATE_PROFESSIONAL: baseURL + 'turno/paciente/turnosProfesionalDia',
    GET_TURNO_BY_DATE_PROFESSIONAL_LIBRE: baseURL + 'turno/libre/turnosDisponible',
    GET_TURNOS_DISP_NO_DISP: baseURL + 'turno/todos/turnosProfesional?prof_cod=',
    GET_OBRAS_SOCIALES: baseURL + 'obrasocial/all',
    PUT_OBRAS_SOCIALES: baseURL + 'obrasocial/update',
    POST_OBRAS_SOCIALES: baseURL + 'obrasocial/createObraSocial',
    GET_OBRA_SOCIAL_BY_COD: baseURL + 'obrasocial/codigo/',
    GET_PROFESIONAL_BY_COD: baseURL + 'profesional/',
    GET_HORARIOS_BY_PROFESSIONAL: baseURL + 'horario/dateProfesional/',
    GET_FERIADOS: baseURL + 'feriado/all',
    DELETE_PROFESIONAL: baseURL + 'profesional/update/',
    TURN_PUT_URL: baseURL + 'turno/update',
    HC_PUT_URL: baseURL + 'historiaclinica/update/',
    SCHEDULE_PUT_URL: baseURL + 'horario/updateAll',
    PACIENTE_PUT_URL: baseURL + 'paciente/update/',
    PACIENTE_PUT_URL_ID: baseURL + 'paciente/updateId/',
    DELETE_HORARIO: baseURL + 'horario/deleteHorario/',
    DELETE_TURNO: baseURL + 'turno/deleteTurno/'

};

export const CONFIG = {
    headers: {
        'Content-Type': 'application/json'
    }
};
