import { useEffect, useState } from 'react';
import { getLocalidadesService } from '../services/data.service';

export function useLocalidades () {
    const [localidades, setLocalidades] = useState([]);
    const getLocalLocalidades = async () => {
        try {
            const localidades = await getLocalidadesService();
            setLocalidades(localidades);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getLocalLocalidades();
    }, []);
    return { localidades };
}
