import React, { useState } from 'react';

export default function Pagination ({ initialValue, totalNumbers, onChange }) {
    const [currentPage, setCurrentPage] = useState(initialValue);

    const handlePageClick = (page) => {
        setCurrentPage(page);
        onChange(page);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalNumbers; i++) {
            pageNumbers.push(
                <li
                    key={i}
                    className={`inline-block mx-1 px-3 py-1 rounded cursor-pointer ${i === currentPage ? 'bg-tradewind-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="pagination mt-4 flex items-center justify-end">
            <>
                {renderPageNumbers()}
            </>
        </div>
    );
}
