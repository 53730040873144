import { useEffect, useState } from 'react';
import { getAllObrasociales } from '../services/data.service';

export function useObraSociales () {
    const [obrasociales, setObrasociales] = useState([]);
    const getObrasSociales = async () => {
        try {
            const obrasociales = await getAllObrasociales();
            setObrasociales(obrasociales);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getObrasSociales();
    }, []);
    return { obrasociales };
}
