import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CSS_INPUT } from '../constants/const_tailwind';

export default function InputSelect ({ data, pars, initialValue, onChange }) {
    const [opt, setOpt] = useState('');
    const [opts, setOpts] = useState(data || []);
    useEffect(() => {
        if (typeof initialValue !== 'undefined') {
            setOpt(initialValue);
        }
    }, [initialValue]);

    useEffect(() => {
        setOpts(data);
    }, [data]);
    const className = pars.darkMode ? ' rounded' : '';
    const handleChange = (event) => {
        setOpt(event.target.value);
        if (onChange !== undefined) {
            const iddata = data.filter(d => d.text === event.target.value);
            event.target.name = pars.ide;
            onChange(event, iddata);
        }
    };
    return (
        <div className={'flex flex-col mx-3'}>
            <label className={pars.darkMode ? CSS_INPUT.DARK_THEME_LABEL : CSS_INPUT.DEFAULT_THEME_LABEL}>{pars.label}</label>
            <Box>
                <FormControl key={pars.ide} fullWidth size="small" className={'bg-tradewind-50' + className }>
                    <Select
                        id={pars.ide}
                        key={pars.ide}
                        value={opt}
                        onChange={handleChange}
                        fullWidth
                        className='max-h-60'
                    >
                        {opts && opts.length > 0
                            ? opts.map(op => (
                                <MenuItem key={op.id} value={op.text} disabled={!!op.disabled}>{op.text}</MenuItem>
                            )
                            )
                            : <MenuItem disabled> No hay resultados</MenuItem>
                        }
                    </Select>
                </FormControl>
            </Box>
        </div>
    );
}
